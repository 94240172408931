import { TrelloStorage } from '@trello/storage';

import type { RegisteredDynamicConfigFlagKey } from './data/dynamicConfigFlags';
import { dynamicConfigFlags } from './data/dynamicConfigFlags';
import {
  CLIENT_STORAGE_KEY,
  OVERRIDES_STORAGE_KEY,
} from './dynamicConfig.constants';
import type { FlagSet, SupportedFlagTypes } from './dynamicConfig.types';

const MEMOIZED_KEYS: RegisteredDynamicConfigFlagKey[] = [
  'trello_web_native_current_board_info',
] as const;

/**
 * There might be configs that are fetched multiple times per page load,
 * we want to make sure we get a consistent evaluation per page load.
 */
const memoizedConfigValues: Partial<FlagSet> = {};

export const dangerouslyGetDynamicConfigSync = (
  flagKey: RegisteredDynamicConfigFlagKey,
): SupportedFlagTypes => {
  const remoteData = TrelloStorage.get(CLIENT_STORAGE_KEY) ?? {};
  const remoteFlags: Record<
    RegisteredDynamicConfigFlagKey,
    { value: SupportedFlagTypes }
  > = JSON.parse(remoteData.config ?? '{}');
  const overrides: FlagSet = TrelloStorage.get(OVERRIDES_STORAGE_KEY) || {};

  const keyRemote = remoteFlags[flagKey]?.value;
  const keyOverride = overrides[flagKey];
  const defaultFlagValue = dynamicConfigFlags[flagKey].defaultValue;

  const flagValue = keyOverride ?? keyRemote ?? defaultFlagValue;

  if (MEMOIZED_KEYS.includes(flagKey)) {
    // Memoize the value
    if (memoizedConfigValues[flagKey] === undefined) {
      memoizedConfigValues[flagKey] = flagValue;
    }
    return memoizedConfigValues[flagKey]!;
  }

  return flagValue;
};
